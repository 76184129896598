
@keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes FadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.project-card.fade-in {
    animation: FadeIn 1s ease-in-out;
}
.project-card.invis {
    animation: FadeOut 1s ease-in-out;
    display: hidden;
}
.project-card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
    background-color: rgba(0, 0, 0, 0.5);
}

.project-card {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 0.5rem;
    margin-left: max(10vw, 0.5rem);    
    margin-right: max(10vw, 0.5rem);
    padding: 1rem;
    align-items: center;
    align-self: center;
    display: inline-block;
    backdrop-filter: blur(2px);
    width: max(50vw, 300px);
}

.projects-display-short {
    backdrop-filter: hue-rotate(-45deg);
}

.project-cards {
    margin-top: max(10vh, 1rem);
    margin-bottom: max(10vh, 1rem);
}

.projects-display-long {
    backdrop-filter: blur(2px);
}

.project-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 0.1rem;
}

.project-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 0.1rem;
}

.project-card-description {
    font-size: 1rem;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
}

.project-image {
    /* small */
    width: max(30vw, 200px);
    height: auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 1rem;
}

.project-long a {
    color: white;
}

.project-long.fade-in {
    animation: FadeIn 1s ease-in-out;
}

.project-long.invis {
    animation: FadeOut 1s ease-in-out;
    display: hidden;
}

.project-long {
    margin-left: max(10vw, 1rem);
    margin-right: max(10vw, 1rem);
    margin-top: max(10vh, 1rem);
    margin-bottom: max(10vh, 1rem);
    text-align: justify;
}

@keyframes FadeInSlideIn {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 0.9;
        transform: translate(-50%, -50%) scale(1);
    }
}
.description {
    opacity: 0.9;
    animation-name: FadeInSlideIn;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    /* animation-name: FloatEthereally;
    animation-duration: 20s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite; */
    /* animation-name: FadeInSlideIn FloatEthereally;
        animation-duration: 4s 20s;
        animation-timing-function: ease-in-out ease-in-out;
        animation-iteration-count: 1 infinite; */
    font-size: 1.5rem;
    color: white;
    font-family: monospace;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: max(50vw, 20rem);
    /* make text a little transparent*/
}
